import * as http from './http'
import ApiService from './service'

export default class PaymentService extends ApiService {
  public async gerPaymentDetails(ID: string, configID: string, amount: string) {
    const url = `${this.apiDomain}mobileapp/payment/getDetails/ID/${ID}/configID/${configID}/amount/${amount}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )

    return resposne?.data?.data
  }

  public async payNow(id: string, orderID: string) {
    const url = `${this.apiDomain}mobileapp/payment/paynow/id/${id}/orderID/${orderID}/`

    const resposne = await http.post(
      url,
      {},
      this.languageID,
      this.websiteID,
      this.subsiteID
      // 'multipart/form-data'
    )

    return {
      data: resposne?.data?.data,
      responsecode: resposne.data?.meta?.responsecode,
      status: resposne.data?.meta?.status,
      message: ''
    }
  }
}
